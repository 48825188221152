import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from '@reach/router';
import './styleWelcome.css';
import ExistingProfileCard from './componentUtils/existingProfileCard';
import { AppContext } from './Context';
import { getMatchingProfile } from '../../actions/matrimonialAction';
import LoadingSpinner from '../loader';

function FoundMatches() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get('name');
  const matrimonialId = params.get('matrimonialId');
  const { formValues } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [matchingProfiles, setMatchingProfiles] = useState(null);

  useEffect(() => {
    console.log('form values are ', formValues);
    const fetchData = async () => {
      setLoading(true);
      const result = await getMatchingProfile(matrimonialId);
      console.log('matching profile response ', result);
      setLoading(false);
      if (result?.status) setMatchingProfiles(result?.matching_profiles);
    };
    fetchData();
  }, []);
  return (
    <div>
      <div className="head-text">{`Matching Profiles For : ${name}`}</div>
      {loading ? (
        <LoadingSpinner message="Fetching Matched Profiles" />
      ) : (
        matchingProfiles?.map((profile, index) => (
          <ExistingProfileCard profile={profile} key={index} edit={false} matchingProfileData={true} />
        ))
      )}
    </div>
  );
}
export default FoundMatches;
